import React from 'react';
import { FullUserProfile } from 'types';

import config from "config";
import './IndividualItem.scss';


const IndividualItem = ({ userProfile }: { userProfile: FullUserProfile }) => {
  const getSelectedGenres = (genres: any[]): string[] => {
    let selectedGenres: string[] = [];

    genres.forEach((genre) => {
      if (genre.selected) {
        selectedGenres.push(genre.name);
      }
      if (genre.children && genre.children.length > 0) {
        selectedGenres = selectedGenres.concat(getSelectedGenres(genre.children));
      }
    });

    return selectedGenres;
  };

  const selectedGenres = getSelectedGenres(userProfile.genres2_set);

  return (
    <>
      <li className="groups-result">
        <div className="group-info">
          <div className="group-data">
            <p className="text-center">
              {userProfile.avatar ? (
                <img src={`${userProfile.avatar}`} />
              ) : (
                <>
                  <img src="" style={{ display: "none" }} alt="group profile" />
                  <span className="group-profile-picture"></span>
                </>
              )}
            </p>

            <h5><a href={`/profile/view/${userProfile.id}`} target="_blank" rel="noopener noreferrer">{userProfile.pen_name}</a></h5>
            <div className="text p-small group-description" dangerouslySetInnerHTML={{ __html: userProfile.bio }}></div>

            <p className="p-small">
              <strong>Genres</strong>
              <br />
              <span className="italic">
                {selectedGenres.map((genre: string, index: number) => (
                  <React.Fragment key={index}>
                    {genre}
                    {index < selectedGenres.length - 1 && " / "}
                  </React.Fragment>
                ))}
              </span>
            </p>
          </div>

          <div className="group-footer">
            <div className="btn-container">
              <a href={`/profile/view/${userProfile.id}`} target="_blank" rel="noopener noreferrer" className="btn purple">View profile</a>
              <a
                href={`/messages/compose/${userProfile.user}/`}
                className="btn blue"
                target="_blank" rel="noopener noreferrer"
              >
                Contact
              </a>
            </div>
          </div>
        </div>
     </li>
    </>
  );
}

export default IndividualItem;