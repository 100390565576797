import React, { useContext, useState, useEffect } from "react";
import { Col, Row, Spinner } from 'react-bootstrap';
import AuthenticationContext from "contexts/AuthenticationContext";
import {
  useForm,
  SubmitHandler,
  Controller,
} from "react-hook-form";

import { FullUserProfile, SearchProps } from 'types';
import useFetch from "custom-hooks/useFetch";
import GenreSelector from "components/genre-selector/GenreSelector";
import { searchingForGroupOptions } from 'options/SearchingForGroup';
import { getGenreListIds } from "utils";
import IndividualItem from "../IndividualItem";
import PaginationButtons from "components/pagination-buttons/PaginationButtons";

const IndividualTab = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<SearchProps>({
    mode: "onBlur",
  });

  const [state, fetchData] = useFetch();
  const [genres, setGenres] = useState([]);
  const authenticationContext = useContext(AuthenticationContext);
  const [showFilters, setShowFilters] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchData({
      url: "api/v1.0/genres/",
      method: "GET",
      token: authenticationContext.token,
    }).then((response: any) => {
      setGenres(response);
    });
  }, [authenticationContext.token]);

  const handleToggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const onSubmit: SubmitHandler<SearchProps> = async (data) => {
    /* Example api call:
      - group/api/search/?q=&model=inkedvoicesuser
      - group/api/search/?q=user+name&model=inkedvoicesuser
      - group/api/search/?q=user+name&type_of_group=0&model=inkedvoicesuser
      - group/api/search/?q=user+name&genres=28&genres=29&genres=30&genres=31&model=inkedvoicesuser
    */
    const PAGE_SIZE = 6;
    let url = `group/api/search/?page=${currentPage || 1}&`;

    if (data.query) {
      url += `q=${encodeURIComponent(data.query)}&`;
    } else {
      url += `q=&`;
    }

    if (genres && genres.length > 0) {
      const genreIds = getGenreListIds(genres);
      genreIds.forEach((id: number) => {
        url += `genres=${id}&`;
      });
    }

    if (Array.isArray(data.user_filters)) {
      data.user_filters.forEach((type) => {
        url += `user_filters=${type}&`;
      });
    } else if (data.user_filters) {
      url += `user_filters=${data.user_filters}&`;
    }

    url += "model=inkedvoicesuser";

    setIsLoading(true);
    try {
      const result = await fetchData({
        url: url,
        method: "GET",
        token: authenticationContext.token,
      });
      setSearchResults(result.results.users);
      setTotalPages(Math.ceil(result.count / PAGE_SIZE));
      setHasSearched(true);
    } catch (error) {
      console.error("Failed to fetch users:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div id="groups" className="group-tab">
      <form onSubmit={handleSubmit(onSubmit)}>
        <br />
        <Row>
          <Col md={12}>
            <label>Keywords</label>
            <input type="text" {...register("query")} />
            <br />
            <a
              href="#"
              className="individual-additional-filters-text"
              style={{ fontWeight: 700, color: '#7f4f80' }}
              onClick={handleToggleFilters}
            >
              Additional filters
            </a>
          </Col>
        </Row>
        <div style={{ display: 'none' }}>
          <input name="model" value="inkedvoicesuser" />
        </div>
        {showFilters && (
          <Row className="individual-additional-filters">
            <Col md={6}>
              <label>Status</label>
              <hr />
              <div className="search-group-form user-filter user-search-filters" style={{ paddingTop: '10px' }}>
                <ul id="id_user_filters" className="custom-checkbox">
                  {searchingForGroupOptions.map((option, index) => (
                    option.value !== 2 && (
                      <li key={`id_user_filters_${index}`} className="custom-checkbox">
                        <label className="custom-checkbox">
                          <input
                            type="checkbox"
                            value={option.value}
                            {...register("user_filters")}
                            className="custom-checkbox"
                          />{" "}
                          {option.label}
                        </label>
                      </li>
                    )
                  ))}
                </ul>
              </div>
            </Col>
            <Col md={6}>
              <label>Genres</label>
              <hr />
              <Controller
                name="genres"
                control={control}
                render={({ field: { value, onChange, onBlur } }) => {
                  return (
                    <GenreSelector
                      genres={genres}
                      level={0}
                      onChange={(e: any) => {
                        setGenres(e);
                      }}
                    />
                  );
                }}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col md={9} className="main-btn">
            <input type="submit" className="btn btn-purple" value="Search" disabled={isLoading} />
          </Col>
        </Row>
      </form>
      {isLoading ? (
        <div className="loading-indicator">
          <Spinner animation="border" role="status" />
          <span>Loading...</span>
        </div>
      ) : hasSearched && searchResults.length > 0 ? (
        <>
          <ul className="groups-result">
            {searchResults.map((userProfile: FullUserProfile, index: number) => (
              <IndividualItem key={index} userProfile={userProfile} />
            ))}
          </ul>
          <div className="pagination">
            <PaginationButtons
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={(page: any) => setCurrentPage(page)}
              firstPageLabel="Newest"
              lastPageLabel="Oldest"
            />
          </div>
        </>
      ) : (
        <p>No results found</p>
      )}
    </div>
  );
};

export default IndividualTab;
