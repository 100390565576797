import React, { useState, useEffect } from "react";
import { Container, Col, Form, Row } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
// import { useHistory, useLocation, useParams } from "react-router-dom";
import { useParams } from "react-router-dom";

import sendRequest from "services/dataService";
import useFetch from "custom-hooks/useFetch";
import config from "config";
import GenreSelector from "components/genre-selector/GenreSelector";
// import { subscriptionUserRoute, pricingRoute } from "routes/endpoints";
import { pricingRoute } from "routes/endpoints";

import "./SignUpSelectGenres.scss";
import { getGenreListIds } from "utils";

interface SignUpSelectGenreFormData {
  email: string;
  main_focus: string;
  user_id: string;
  genres2_set: any[];
}

const SignUpSelectGenres = () => {
  const { userId } = useParams<{ userId: string }>();
  const [state, fetchData] = useFetch();
  const [genres, setGenres] = useState([]);
  const {
    register,
    handleSubmit,
    setError,
    control,
    formState: { errors, isSubmitting },
  } = useForm<SignUpSelectGenreFormData>();

  useEffect(() => {
    fetchData({
      url: "api/v1.0/genres/",
      method: "GET",
    }).then((response: any) => {
      setGenres(response);
    });
  }, [fetchData]);

  const onSubmit = async (data: SignUpSelectGenreFormData) => {
    if (!userId) {
      toast.error("No user ID found");
      return;
    }
    data.user_id = userId;
    data.genres2_set = getGenreListIds(genres);

    try {
      await sendRequest({
        url: `${config.BASE_API_URL}profile/api/signup-userprofile/`,
        method: "PUT",
        token: "",
        body: JSON.stringify({ ...data }),
      });

      toast.success("Profile created successfully!");
      window.location.href = `${config.BASE_API_URL}account/subscribe_new_user/${userId}/`;
    } catch (error) {
      toast.error("Profile creation failed. Please try again.");
    }
  };

  const mainFocusOptions = [
    { value: "", label: "Select your main focus" },
    { value: "Picture Books", label: "Picture Books" },
    { value: "Middle Grade", label: "Middle Grade" },
    { value: "Young Adult", label: "Young Adult" },
    { value: "Adult", label: "Adult" },
  ];

  return (
    <Container className="signup-container">
      <Col md={{ span: 8, offset: 2 }} className="sign-up">
        <h1>Sign Up</h1>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <h3>What kind of writing do you do?</h3>

            <Row>
              <Col md={12} className="setGenreSignup">
                <Controller
                  name="genres2_set"
                  control={control}
                  render={({ field: { value, onChange, onBlur } }) => {
                    return (
                      <GenreSelector
                        genres={genres}
                        level={0}
                        onChange={(e: any) => {
                          setGenres(e);
                          onChange(e); // Ensure the form state is updated
                        }}
                      />
                    );
                  }}
                />
              </Col>
            </Row>
          </div>

          <div>
            <h3>Which of these is your main focus at Inked Voices?</h3>
            <p>You are welcome to work across genres here. Based on your selection, we will introduce you to our community lead for picture books, middle grade and young adult, or adult.</p>

            <Row className="row">
              <Col md={12}>
                <Form.Group className="form-group">
                  <Form.Control
                    as="select"
                    {...register("main_focus", { required: "Main focus is required." })}
                    className="col-md-6"
                  >
                    {mainFocusOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Control>
                  {errors.main_focus && (
                    <small className="text-danger">{errors.main_focus.message}</small>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </div>

          <div style={{ marginTop: "2%" }}>
            <input type="submit" className="btn btn-large purple" value="Select your plan" id="select-plan-btn"/>
            <a className="purple-link" href={pricingRoute} target="_blank" rel="noreferrer">Tell me more about the plans</a>
          </div>
        </Form>
      </Col>
    </Container>
  );
};

export default SignUpSelectGenres;