import React, { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Col, Container, Row, Spinner, Form } from "react-bootstrap";
import { loadStripe } from '@stripe/stripe-js';

import config from "config";
import sendRequest from "services/dataService";
import AuthenticationContext from "contexts/AuthenticationContext";

import { termsOfServiceRoute, codeOfConductRoute } from "routes/endpoints";

import "./Subscribe.scss";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);

interface SubscribeFormData {
  coupon: string;
  terms_conditions: boolean;
  min_age: boolean;
  skip_trial: boolean;
}

const Subscribe = () => {
  const { userId } = useParams() as any;
  const invite_by = useSelector((state: any) => state.user);
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState<string>("Annual");
  const authenticationContext = useContext(AuthenticationContext);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<SubscribeFormData>();

  useEffect(() => {
    const url = `api/v1.0/user/${userId}/`;
    sendRequest({
      url,
      method: "GET",
      token: authenticationContext.token || "",
      body: null,
    }).then((response: any) => {
      setUser(response);
    });
  }, [userId, authenticationContext.token]);

  const handleCouponBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
    e.preventDefault();
    const coupon = e.target.value;
    if (coupon) {
      setLoading(true);
      try {
        const response = await sendRequest({
          url: `api/v1.0/validate-coupon/`,
          method: "POST",
          token: authenticationContext.token || "",
          body: JSON.stringify({ coupon }),
        });
        if (response.valid) {
          toast.success("Coupon is valid!");
        } else {
          toast.error("Coupon is invalid.");
        }
      } catch (error) {
        toast.error("Failed to validate coupon. Please try again.");
      } finally {
        setLoading(false);
      }
    }
  };

  const handlePlanSelect = (plan: string) => {
    setSelectedPlan(plan);
  };

  const onSubmit = async (data: SubscribeFormData) => {
    try {
      const stripe = await stripePromise;
      if (!stripe) throw new Error('Stripe failed to load');

      // Use the appropriate price ID based on selected plan
      const priceId = selectedPlan === "Annual" 
        ? config.ANNUAL_PRICE_ID
        : config.QUARTERLY_PRICE_ID;

      const { error } = await stripe.redirectToCheckout({
        lineItems: [{ price: priceId, quantity: 1 }],
        mode: 'subscription',
        successUrl: `${window.location.origin}/subscription/success`,
        cancelUrl: `${window.location.origin}/subscribe`,
      });

      if (error) {
        toast.error(error.message || 'An error occurred during checkout.');
      }
    } catch (error) {
      toast.error('Failed to initialize checkout. Please try again.');
    }
  };

  return (
    <Container className="subscribe">
      <div className="sign-up">
        <h1>Payment subscription</h1>
        <div className="select-plan">
          <ul id="general_errors" className="errorlist" style={{ display: "none" }}></ul>
          <h3>Select the plan that works for you:</h3>
          <button
            type="button"
            className={`plan ${selectedPlan === "Quarterly" ? "selected" : ""}`}
            onClick={() => handlePlanSelect("Quarterly")}
          >
            <span className="ico-selected"></span>
            <h4>QUARTERLY</h4>
            <p>
              <div className="main-line" data-value="36.00" data-original="/ billed every 3 months">
                <div className="price">$41</div>
                / billed every 3 months
              </div>
            </p>
            <p className="amount">
              <div className="second-line" data-value="13.66" data-original="per month">
                <div className="price">$13.66</div>
                per month
              </div>
            </p>
          </button>
          <button
            type="button"
            className={`plan ${selectedPlan === "Annual" ? "selected" : ""}`}
            style={{ marginLeft: "1%" }}
            onClick={() => handlePlanSelect("Annual")}
          >
            <span className="ico-selected"></span>
            <h4>ANNUAL</h4>
            <p>
              <div className="main-line" data-value="99.00" data-original="/ billed annually">
                <div className="price">$112</div>
                / billed annually
              </div>
            </p>
            <p className="amount">
              <div className="second-line" data-value="9.33" data-original="per month">
                <div className="price">$9.33</div>
                per month (save 31.69%)
              </div>
            </p>
          </button>
          <input type="hidden" id="id_plan" value={selectedPlan} />
        </div>

        <Form onSubmit={handleSubmit(onSubmit)}>
          {user && !user.used_trial_period && (
            <div style={{ marginTop: "4%" }}>
              <p className="purple-text"> All plans come with a 14-day free trial.</p>
              <Row>
                <Col md={3}>
                  <Form.Switch 
                    type="switch"
                    id="skip-trial-switch"
                    label="Skip free trial"
                    {...register("skip_trial")}
                  />
                </Col>
                <Col md={6} style={{ marginTop: "-1%" }}>
                  <p>To register for workshops, attend live webinars or view the webinars archive now, skip your free trial.</p>
                </Col>
              </Row>
            </div>
          )}
          <div className="legal-terms">
            <Form.Group controlId="terms_conditions" className="terms_label">
              <Form.Check>
                <Form.Check.Input 
                  type="checkbox"
                  {...register("terms_conditions", { 
                    required: "You must accept the terms and conditions." 
                  })}
                />
                <Form.Check.Label>
                  I have read and understand the <a href={termsOfServiceRoute} target="_blank" rel="noreferrer" className="blue-link">Terms, Conditions & Privacy Policy</a> and <a href={codeOfConductRoute} target="_blank" rel="noreferrer" className="blue-link">Code of Conduct</a>.
                </Form.Check.Label>
              </Form.Check>
              {errors.terms_conditions && (
                <Form.Text className="text-danger">{errors.terms_conditions.message}</Form.Text>
              )}
            </Form.Group>
            <br />

            <Form.Group controlId="min_age" className="terms_label">
              <Form.Check>
                <Form.Check.Input 
                  type="checkbox"
                  {...register("min_age", { 
                    required: "You must be at least 13 years of age." 
                  })}
                />
                <Form.Check.Label>
                  I am at least 13 years of age.
                </Form.Check.Label>
              </Form.Check>
              {errors.min_age && (
                <Form.Text className="text-danger">{errors.min_age.message}</Form.Text>
              )}
            </Form.Group>
            <br />

            <Row>
              <Col md={6}>
                <Form.Group controlId="coupon">
                  <Form.Label>Coupon code:</Form.Label>
                  <Form.Control
                    type="text"
                    {...register("coupon")}
                    onBlur={handleCouponBlur}
                  />
                </Form.Group>
              </Col>
              <Col md={6} style={{ display: "flex", alignItems: "end" }}>
                <div className="small_spinner_container">
                  {loading && <Spinner animation="border" role="status" />}
                </div>
              </Col>
            </Row>
            <br />

            <ul id="invite_by_errors" className="errorlist" style={{ display: "none" }}></ul>
          </div>

          <p>After completing checkout, you will receive an email to activate your account.</p>

          <button type="submit" className="btn btn-large" disabled={isSubmitting}>
            {isSubmitting ? "Processing..." : "CHECKOUT"}
          </button>
        </Form>
      </div>
    </Container>
  );
};

export default Subscribe;