import React from "react";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { FormattedMessage } from "react-intl";
import { NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

import Config from "../../config";
import WorkshopDropdown from "./EventsDropdown";
import { CurrentUserState } from "types";
import { newGroupRoute, groupSearchRoute } from "routes/endpoints";

type HeaderUserAuthenticatedProps = {
  userState: CurrentUserState;
};

const HeaderUserAuthenticated = ({
  userState,
}: HeaderUserAuthenticatedProps) => {
  return (
    <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="mr-auto">
        <Nav.Link as={Link} to="/dashboard/">
          <FormattedMessage id="header.dashboard" />
        </Nav.Link>
        {/* <Nav.Link href={Config.BASE_API_URL + "dashboard/"}>
          <FormattedMessage
            id="header.dashboard"
            defaultMessage={"DASHBOARD"}
          />
        </Nav.Link> */}
        <NavDropdown
          id="group-dropdown"
          title={<FormattedMessage id="header.groups" />}
        >
          <NavDropdown.Item
            eventKey="create-a-group"
            id="create-a-group"
            as={Link}
            to={newGroupRoute}
          >
            Create A Group
          </NavDropdown.Item>
          <NavDropdown.Item
            eventKey="search"
            id="search-groups"
            as={Link}
            to={groupSearchRoute}
          >
            Search
          </NavDropdown.Item>
          {userState.data?.memberships?.map((membership: any) => (
            <Nav.Link
              as={Link}
              eventKey="Membership"
              id="membership"
              to={"/" + membership.group.url?.substring(1)}
            >
              {membership.group.name}
            </Nav.Link>
          ))}
        </NavDropdown>
        <WorkshopDropdown />
        <NavDropdown
          id="resources-dropdown"
          title={<FormattedMessage id="header.resources" />}
        >
          <NavDropdown.Item
            eventKey="critique-guidelines"
            id="critique-guidelines"
            as={Link}
            to={"/resources/critique-guidelines/"}
          >
            Giving Critique
          </NavDropdown.Item>
          <NavDropdown.Item
            eventKey="picture-books-critique-guide"
            id="picture-books-critique-guide"
            as={Link}
            to={"/resources/picture-books-critique-guide/"}
          >
            Picture Books Critique <br /> Guide
          </NavDropdown.Item>
          <NavDropdown.Item
            eventKey="critique-guidelines-fiction"
            id="critique-guidelines-fiction"
            as={Link}
            to={"/resources/critique-guidelines-fiction/"}
          >
            Fiction Critique Guide
          </NavDropdown.Item>
          <NavDropdown.Item
            eventKey="kidlit-literary-agents-list"
            id="kidlit-literary-agents-list"
            as={Link}
            to={"/resources/kidlit-literary-agents-list/"}
          >
            Kidlit Literary Agents
          </NavDropdown.Item>
          <NavDropdown.Item
            eventKey="one-page-synopsis"
            id="one-page-synopsis"
            as={Link}
            to="/resources/one-page-synopsis/"
          >
            One-Page Synopsis
          </NavDropdown.Item>
        </NavDropdown>
        {/* <Nav.Link as={Link} to="/forum">
          <FormattedMessage id="header.forum" />
        </Nav.Link> */}
      </Nav>
      <span className="spacer" />
      <NavDropdown
        id="user-dropdown"
        title={
          <>
            <img
              alt="nav-profile"
              className="nav-profile-image"
              src={userState.data.avatar}
              width={24}
              height={24}
            />
            <span className="user-name">{userState.data.pen_name}</span>
          </>
        }
      >
        <NavDropdown.Item
          eventKey="Edit My Profile"
          id="edit-profile"
          as={Link}
          to="/profile/edit/"
        >
          Edit My Profile
        </NavDropdown.Item>
        <NavDropdown.Item
          eventKey="My Account"
          id="my-account"
          as={Link}
          to="/account/edit/"
        >
          My Account
        </NavDropdown.Item>
        <NavDropdown.Item
          eventKey="Help"
          id="help"
          href={Config.BASE_API_URL + "help/"}
          target="_blank"
        >
          Help
        </NavDropdown.Item>
        <NavDropdown.Item
          eventKey="Logout"
          id="logout"
          href={Config.BASE_API_URL + "account/logout/"}
        >
          Logout
        </NavDropdown.Item>
      </NavDropdown>

      <Link to={"/messages/inbox/"}>
        {userState.data?.unread_messages ? (
          <div style={{ marginTop: 15 }}>
            <img
              alt="img-msg"
              src={Config.BASE_API_URL + "static/img/newmessages.png"}
            />
            <div className="messages-number">
              {userState.data?.unread_messages}
            </div>
          </div>
        ) : (
          <img
            alt="img-msg"
            src={Config.BASE_API_URL + "static/img/nomessages.png"}
          />
        )}
      </Link>
    </Navbar.Collapse>
  );
};

export default HeaderUserAuthenticated;
