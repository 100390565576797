import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Routes from './routes';
import SignUp from "pages/signup";
import signupSelectGenres from "pages/signup-select-genres";
import Subscribe from "pages/subscribe";

import {
  signupRoute,
  subscriptionUserRoute,
  signupSelectGenresRoute,
} from "./endpoints";

const AppRoutes = () => {
  return (
    <Switch>
      <Route exact path={signupRoute} component={SignUp} />
      <Route exact path={signupSelectGenresRoute(":userId")} component={signupSelectGenres} />
      <Route exact path={subscriptionUserRoute(":userId")} component={Subscribe} />
      <Route component={Routes} />
    </Switch>
  );
};

export default AppRoutes;