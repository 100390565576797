import { Row, Col, Table } from "react-bootstrap";
import ReactPlaceholder from "react-placeholder";
import { createRef, useContext, useEffect, useState } from "react";
import sendRequest from "services/dataService";
import AuthenticationContext from "contexts/AuthenticationContext";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { CurrentUserState } from "types";
export default function PasswordForm() {
  const userState = useSelector(
    (state: { user: CurrentUserState }) => state.user
  );
  const passwordRef = createRef<HTMLInputElement>();
  const passwordRef2 = createRef<HTMLInputElement>();

  const authenticationContext = useContext(AuthenticationContext);

  const clearFields = () => {
    if (passwordRef.current) {
      passwordRef.current.value = "";
    }
    if (passwordRef2.current) {
      passwordRef2.current.value = "";
    }
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!passwordRef.current || !passwordRef2.current) {
      return;
    }
    if (passwordRef.current.value.length == 0) {
      toast.error("Please enter a password", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    if (passwordRef.current.value !== passwordRef2.current.value) {
      toast.error("Passwords don't match", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }

    sendRequest({
      method: "PATCH",
      url: `api/v1.0/user/${userState.data.id}/update-password/`,
      token: authenticationContext.token || "",
      body: JSON.stringify({
        new_password1: passwordRef.current.value,
        new_password2: passwordRef2.current.value,
      }),
    })
      .then((response) => {
        toast.success("Password changed successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        clearFields();
      })
      .catch((error: any) => {
        const msg = JSON.parse(error.message);
        toast.error(msg.password[0].replace("['", "").replace("']", ""), {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Table>
        <Row>
          <Col className="control-label" sm={3}>
            New Password:
          </Col>
          <Col sm={8}>
            <ReactPlaceholder rows={1} type="text" ready={true}>
              <input
                name="password"
                type="password"
                className="form-control"
                required
                ref={passwordRef}
              />
            </ReactPlaceholder>
          </Col>
        </Row>
        <Row>
          <Col className="control-label" sm={3}>
            New Password Confirmation:
          </Col>
          <Col sm={8}>
            <ReactPlaceholder rows={1} type="text" ready={true}>
              <input
                name="password2"
                type="password"
                className="form-control"
                required
                ref={passwordRef2}
              />
            </ReactPlaceholder>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center">
              <button className="btn btn-primary" type="submit">
                Save
              </button>
              <button className="btn cancel" type="reset" onClick={clearFields}>
                Cancel
              </button>
            </div>
          </Col>
        </Row>
      </Table>
    </form>
  );
}
