import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeCardElement, TokenResult } from "@stripe/stripe-js";
import AuthenticationContext from "contexts/AuthenticationContext";
import { FormEvent, useContext, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import sendRequest from "services/dataService";
import { CurrentUserState } from "types";

const CreditCardForm = ({
  onSuccess,
  onCancel,
}: {
  onSuccess: () => void;
  onCancel: () => void;
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const cardNameRef = useRef<HTMLInputElement>(null);
  const userState = useSelector(
    (state: { user: CurrentUserState }) => state.user
  );
  const authenticationContext = useContext(AuthenticationContext);
  const [error, setError] = useState<string | null>(null);

  const createToken = async (
    card_name: string,
    cardElement: StripeCardElement
  ) => {
    const result: TokenResult | undefined = await stripe?.createToken(
      cardElement,
      { name: card_name }
    );
    if (result?.error) {
      setError(result.error.message || null);
      toast.error(result.error.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return null;
    }
    return result?.token;
  };
  const handleSubmitCardChange = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // setIsProcessing(true);

    if (!stripe || !elements) {
      //   setErrorMessage("Stripe has not loaded yet.");
      //   setIsProcessing(false);
      return;
    }

    // Get a reference to the CardElement
    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      return;
    }
    const token = await createToken(
      cardNameRef.current?.value || "",
      cardElement
    );
    if (!token) {
      return;
    }
    
    const response = await sendRequest({
      method: "PATCH",
      url: `api/v1.0/user/${userState.data.id}/subscription/card-info/`,
      token: authenticationContext.token || "",
      body: JSON.stringify({ token: token.id }),
    });
    if (response.message.includes("success")) {
      toast.success(response.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      onSuccess();
    } else {
      toast.error(response.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  return (
    <form
      method="POST"
      id="payment-form"
      className="form-horizontal payment_form"
      role="form"
      onSubmit={handleSubmitCardChange}
    >
      <div className="form-row">
        <label>Credit or debit card</label>

        <div className="group">
          <label>
            <span>Name</span>
            <input
              id="card_name"
              className="field"
              placeholder="Cardholder name"
              ref={cardNameRef}
            />
          </label>
        </div>
        <div className="group">
          <label>
            <span>Card</span>
            <div id="card-element" className="field">
              <CardElement />
            </div>
          </label>
        </div>

        <div id="card-errors" role="alert"></div>
      </div>
      <br />
      <input type="submit" className="btn purple" value="Update Card" />
      <a
        href="#"
        className="btn cancel"
        id="cancel_cc"
        onClick={(e) => {
          e.preventDefault();
          onCancel();
        }}
      >
        Cancel
      </a>
    </form>
  );
};

export default CreditCardForm;
