import AuthenticationContext from "contexts/AuthenticationContext";
import { useContext, useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import ReactPlaceholder from "react-placeholder";
import { useSelector } from "react-redux";
import sendRequest from "services/dataService";
import { CurrentUserState, Subscription } from "types";
import CreditCardForm from "./CreditCardForm";
import CancelSubscriptionModal from "./CancelSubscriptionModal";
import ChangePlanModal from "./ChangePlanModal";
import ReActivateSubscriptionModal from "./ReActivateSubscriptionModal";
import config from "config";
import EndTrialEarlyModal from "./EndTrialEarlyModal";

export default function SubscriptionForm() {
  const authenticationContext = useContext(AuthenticationContext);
  const userState = useSelector(
    (state: { user: CurrentUserState }) => state.user
  );

  const [subscriptionData, setSubscriptionData] = useState<Subscription | null>(
    null
  );
  const [showCreditCardForm, setShowCreditCardForm] = useState(false);
  const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] =
    useState(false);
  const [showChangePlanModal, setShowChangePlanModal] = useState(false);
  const [showReActivateModal, setShowReactivateModal] = useState(false);
  const [showEndTrialModal, setShowEndTrialModal] = useState(false);

  const fetchSubscriptionData = async () => {
    const token = authenticationContext.token || "";
    const response = await sendRequest({
      url: `api/v1.0/user/${userState.data.id}/subscription/`,
      method: "GET",
      body: null,
      token: token,
    });
    setSubscriptionData(response);
  };

  useEffect(() => {
    fetchSubscriptionData();
  }, [userState.data]);

  const oppositePeriod =
    subscriptionData?.period === "annual" ? "quarterly" : "annual";

  const canReactivate =
    subscriptionData?.subscription_canceled_date ||
    subscriptionData?.subscription_will_cancel;

  return (
    <Table>
      <Row>
        <Col sm={{ span: 8, offset: 2 }}>
          <p>Your referral link:</p>
          <pre className="cc_info">
            {`https://www.inkedvoices.com/account/register/referral=${userState.data.id}/`}
          </pre>
        </Col>
      </Row>
      <Row>
        <Col sm={{ span: 8, offset: 2 }}>
          <ReactPlaceholder rows={2} type="text" ready={!!subscriptionData}>
            <p>{subscriptionData?.subscription_text}</p>
          </ReactPlaceholder>
          <div className="p-0">
            {!subscriptionData?.has_stripe_data && (
              <a
                href={
                  config.BASE_API_URL +
                  `account/subscribe_new_user/${userState.data.id}/`
                }
                className="btn purple"
              >
                Subscribe
              </a>
            )}
            {subscriptionData?.has_stripe_data && canReactivate && (
              <>
                <button
                  className="btn cancel"
                  onClick={() => setShowReactivateModal(true)}
                >
                  Re-activate subscription
                </button>
                <ReActivateSubscriptionModal
                  show={showReActivateModal}
                  onHide={(submited) => {
                    submited && fetchSubscriptionData();
                    setShowReactivateModal(false);
                  }}
                />
              </>
            )}

            {subscriptionData?.has_stripe_data && !canReactivate && (
              <>
                <button
                  id="subscribeButton"
                  className="btn"
                  onClick={() => setShowChangePlanModal(true)}
                >
                  Change to {oppositePeriod} plan
                </button>
                <ChangePlanModal
                  show={showChangePlanModal}
                  plan={oppositePeriod}
                  onHide={(submited) => {
                    submited && fetchSubscriptionData();
                    setShowChangePlanModal(false);
                  }}
                />
              </>
            )}
            {subscriptionData?.is_in_free_trial_period && (
              <>
                <button
                  id="end_trial_early"
                  className="btn blue"
                  onClick={() => setShowEndTrialModal(true)}
                >
                  End Trial Early
                </button>
                <EndTrialEarlyModal
                  show={showEndTrialModal}
                  onHide={(submited) => {
                    submited && fetchSubscriptionData();
                    setShowEndTrialModal(false);
                  }}
                />
              </>
            )}
            {!subscriptionData?.subscription_will_cancel && (
              <>
                <button
                  id="unsubscribeButton"
                  className="btn cancel"
                  onClick={() => setShowCancelSubscriptionModal(true)}
                >
                  Cancel subscription
                </button>
                <CancelSubscriptionModal
                  show={showCancelSubscriptionModal}
                  onHide={(canceled) => {
                    canceled && fetchSubscriptionData();
                    setShowCancelSubscriptionModal(false);
                  }}
                />
              </>
            )}
          </div>
        </Col>
      </Row>
      {subscriptionData?.has_stripe_data && (
        <Row>
          <Col sm={{ span: 8, offset: 2 }}>
            <p>The following payment card is currently used for payment:</p>
            {showCreditCardForm ? (
              <>
                <CreditCardForm
                  onSuccess={() => {
                    setShowCreditCardForm(false);
                    fetchSubscriptionData();
                  }}
                  onCancel={() => setShowCreditCardForm(false)}
                />
              </>
            ) : (
              <>
                <pre id="cc_info">
                  {" "}
                  Cardholder name: {subscriptionData?.card_name}
                  <br /> Card number: {subscriptionData?.card_number}
                  <br /> Expiration date: {subscriptionData?.card_expiration}
                </pre>
                <a
                  id="change_cc"
                  href="#"
                  className="pull-right"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowCreditCardForm(true);
                  }}
                >
                  Update Credit Card
                </a>
              </>
            )}
          </Col>
        </Row>
      )}
    </Table>
  );
}
