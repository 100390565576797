import { min } from "lodash";
import { start } from "repl";
import { BaseEvent } from "types";

export const PEER_POP_UPS = "Peer Pop-ups";
export const BOOK_STUDIES = "Book Studies";
export const WRITING_CHALLENGES = "Writing Challenges";
export const MONTHLY_WEBINAR = "Monthly Webinar";
export const MONTHLY_WEBINAR_KEY = "monthly_webinar";
export const LIVE_WRITE_IN = "Live Write-In";
export const LIVE_WRITE_IN_KEY = "live_write_in";

export const getTimezone = () => {
  return new Date()
    .toLocaleDateString(undefined, { day: "2-digit", timeZoneName: "short" })
    .substring(4);
};

export const displayEventDates = (evt: any) => {
  const options = {
    month: "long",
    day: "numeric",
  } as const;
  const startDate = new Date(evt.start_date).toLocaleDateString(
    "en-US",
    options
  );
  const startDateAndYear = new Date(evt.start_date).toLocaleDateString(
    "en-US",
    { ...options, year: "numeric" }
  );
  const endDate = evt.end_date
    ? new Date(evt.end_date).toLocaleDateString("en-US", options)
    : null;
  const endDateAndYear = evt.end_date
    ? new Date(evt.end_date).toLocaleDateString("en-US", {
        ...options,
        year: "numeric",
      })
    : null;

  const dt =
    endDate && startDate !== endDate
      ? `${startDate} - ${endDateAndYear}`
      : `${startDateAndYear}`;
  return dt;
};

export const displayFullEventDatetimes = (evt: BaseEvent) => {
  const start_date_options = evt.end_date
    ? ({
        hour: "numeric",
        minute: "numeric",
        month: "long",
        day: "numeric",
      } as const)
    : ({
        hour: "numeric",
        minute: "numeric",
        year: "numeric",
        month: "long",
        day: "numeric",
      } as const);
  const end_time_options = {
    hour: "numeric",
    minute: "numeric",
  } as const;
  const start_date = new Date(evt.start_date).toLocaleDateString(
    "en-US",
    start_date_options
  );
  const end_time = evt.end_date
    ? new Date(evt.end_date).toLocaleTimeString("en-US", end_time_options)
    : null;
  const endStr = end_time ? ` - ${end_time}` : "";
  const dt = `${start_date}${endStr}`;
  return dt;
};

export const displayDateTime = (dateStr?: string) => {
  if (!dateStr) {
    return "";
  }
  const date_options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  } as const;
  const time_options = { hour: "numeric", minute: "numeric" } as const;
  const dt = new Date(dateStr);
  const d = dt.toLocaleDateString("en-US", date_options);
  const t = dt.toLocaleTimeString("en-US", time_options);
  return `${d} at ${t}`;
};

export const isEventWithApplications = (evt: any) => {
  return evt?.is_application_based;
};

export const isResourceEvent = (evt: any) => {
  return (
    [MONTHLY_WEBINAR_KEY, LIVE_WRITE_IN_KEY].filter(
      (v: any) => v === evt?.event_type
    ).length === 1
  );
};

export const isLiveWriteIn = (evt: any) => {
  return LIVE_WRITE_IN_KEY === evt?.event_type;
};

export const isProGroup = (evt: any) => {
  return evt?.event_type === "workshop";
};

export const eventTagName = (evt: any) => {
  var mapping: any = {};
  mapping[MONTHLY_WEBINAR_KEY] = "Webinar Series";
  mapping[LIVE_WRITE_IN_KEY] = "Write-In";
  return mapping[evt?.event_type];
};

export const displayDate = (dateStr?: string) => {
  if (!dateStr) {
    return "";
  }
  const date_options = { month: "long", day: "numeric" } as const;
  const dt = new Date(dateStr);
  return dt.toLocaleDateString("en-US", date_options);
};

export const displayMonth = (dateStr: string) => {
  if (!dateStr) {
    return "";
  }
  const date_options = { month: "long", year: "numeric" } as const;
  const dt = new Date(dateStr);
  return dt.toLocaleDateString("en-US", date_options);
};

export const displayShortDate = (dateStr: string) => {
  if (!dateStr) {
    return "";
  }
  const date_options = { month: "numeric", day: "numeric" } as const;
  const dt = new Date(dateStr);
  return dt.toLocaleDateString("en-US", date_options);
};

export const displayCondensedDate = (dateStr: string) => {
  if (!dateStr) {
    return "";
  }
  const dt = new Date(dateStr);
  const day = dt.toLocaleDateString("en-US", { day: "2-digit" });
  const month = dt.toLocaleDateString("en-US", { month: "short" });
  const year = dt.toLocaleDateString("en-US", { year: "2-digit" });
  return day + "/" + month + " " + year;
};

export const displayFormattedDate = (dateStr: string) => {
  if (!dateStr) {
    return "";
  }
  const dt = new Date(dateStr);
  const now = new Date();
  const isToday = dt.toDateString() === now.toDateString();

  if (isToday) {
    return dt.toLocaleTimeString("en-US", { 
      hour: "numeric", 
      minute: "numeric",
      hour12: true 
    });
  }

  const day = dt.toLocaleDateString("en-US", { day: "2-digit" });
  const month = dt.toLocaleDateString("en-US", { month: "short" });
  const year = dt.toLocaleDateString("en-US", { year: "numeric" });
  return `${month} ${day}, ${year}`;
};

export const displayShortTime = (dateStr: string) => {
  if (!dateStr) {
    return "";
  }
  const date_options = {
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  } as const;
  const dt = new Date(dateStr);
  return dt.toLocaleTimeString("en-US", date_options);
};

export const getClosedEventMessage = (evt: any) => {
  const buttonActionName = isEventWithApplications(evt)
    ? "applications open"
    : "registration starts";

  if (evt.event_type === MONTHLY_WEBINAR_KEY) {
    return `
            Registration starts ${displayShortDate(
              evt.unrestricted_open_period_start_datetime
            )} at ${displayShortTime(
      evt.unrestricted_open_period_start_datetime
    )}.`;
  }
  if (!evt.unrestricted_open_period_start_datetime) {
    return `
            Members: ${buttonActionName} ${displayShortDate(
      evt.open_period_start_datetime
    )} at ${displayShortTime(evt.unrestricted_open_period_start_datetime)}.`;
  }
  const now = new Date();
  if (
    now < new Date(evt.unrestricted_open_period_start_datetime) &&
    now > new Date(evt.open_period_start_datetime)
  ) {
    return (
      "Members: " +
      (isEventWithApplications(evt) ? "Apply now" : "Register now") +
      `;
            Nonmembers: Opens ${displayShortDate(
              evt.unrestricted_open_period_start_datetime
            )} at ${displayShortTime(
        evt.unrestricted_open_period_start_datetime
      )}.`
    );
  }
  return `
        Members: ${buttonActionName} ${displayShortDate(
    evt.open_period_start_datetime
  )} at ${displayShortTime(evt.unrestricted_open_period_start_datetime)};
        Nonmembers: opens ${displayShortDate(
          evt.unrestricted_open_period_start_datetime
        )} at ${displayShortTime(evt.unrestricted_open_period_start_datetime)}.
    `;
};

export const getOpenEventMessage = (evt: any) => {
  if (isEventWithApplications(evt)) {
    return `Apply by ${displayDate(evt.open_period_end_datetime)}`;
  } else {
    return "Register now";
  }
};

export const isGroup = (evt: any) => {
  // return True if event_type is BOOK_STUDIES, WRITING_CHALLENGES or PEER_POP_UPS
  return (
    [BOOK_STUDIES, WRITING_CHALLENGES, PEER_POP_UPS].filter(
      (v: any) => v === evt?.event_type
    ).length === 1
  );
};

export const buildFilterKey = (id: string, name: string) => {
  return id + "::" + name;
};

export const getRawEventType = (eventTypeFriendlyName: string) => {
  const mapping: any = {
    workshop: "ProGroupSubscription",
    "write-in": "ResourceEvent",
    webinar: "ResourceEvent",
    "pop-up-group": "Group",
    "book-studies": "Group",
    "writing-challenges": "Group",
  };
  return mapping[eventTypeFriendlyName];
};
