import { Row, Col, Table } from "react-bootstrap";
import { set } from "lodash";
import ReactPlaceholder from "react-placeholder";
import { Account, CurrentUserState } from "types";
import { useContext, useEffect, useState } from "react";
import sendRequest from "services/dataService";
import AuthenticationContext from "contexts/AuthenticationContext";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

type FormErrors = {
  email?: string;
  pen_name?: string;
  timezone?: string;
};
export default function AccountForm() {
  const [timezoneOptions, setTimezoneOptions] = useState<
    { name: string; value: string }[] | null
  >(null);

  const [account, setAccount] = useState<Account | null>(null);
  const authenticationContext = useContext(AuthenticationContext);
  const userState = useSelector(
    (state: { user: CurrentUserState }) => state.user
  );
  const [errors, setErrors] = useState<FormErrors>({});

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    sendRequest({
      method: "PATCH",
      url: `api/v1.0/user/${userState.data.id}/account/`,
      token: authenticationContext.token || "",
      body: JSON.stringify({
        email: e.currentTarget.email.value,
        pen_name: e.currentTarget.pen_name.value,
        timezone: e.currentTarget.timezone.value,
      }),
    })
      .then((response) => {
        setErrors({});
        toast.success("Account updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      })
      .catch((error: any) => {
        setErrors(JSON.parse(error.message));
      });
  };

  const fetchTimezoneOptions = async () => {
    const response = await sendRequest({
      url: "api/v1.0/timezones/",
      token: authenticationContext.token || "",
      method: "GET",
      body: null,
    });
    const timezones: string[] = response;
    const options = timezones.map((tz) => ({ name: tz, value: tz }));
    setTimezoneOptions(options);
  };

  const fetchAccount = async () => {
    const token = authenticationContext.token || "";
    const response = await sendRequest({
      url: `api/v1.0/user/${userState.data.id}/account`,
      method: "GET",
      body: null,
      token: token,
    });
    setAccount(response);
  };

  useEffect(() => {
    fetchAccount();
    fetchTimezoneOptions();
  }, [userState.data]);
  return (
    <form onSubmit={handleSubmit}>
      <Table>
        <Row>
          <Col className="control-label" sm={3}>
            E-mail:
          </Col>
          <Col sm={8}>
            <ReactPlaceholder rows={1} type="text" ready={account != null}>
              <input
                name="email"
                type="email"
                className="form-control"
                required
                defaultValue={account?.email}
              />
              {errors.email && (
                <div style={{ color: "red", padding: 0 }}>{errors.email}</div>
              )}
            </ReactPlaceholder>
          </Col>
        </Row>
        <Row>
          <Col className="control-label" sm={3}>
            Name:
          </Col>
          <Col sm={8}>
            <ReactPlaceholder rows={1} type="text" ready={account != null}>
              <input
                name="pen_name"
                type="text"
                className="form-control"
                defaultValue={account?.pen_name}
                required
              />
              {errors.pen_name && (
                <div style={{ color: "red", padding: 0 }}>
                  {errors.pen_name}
                </div>
              )}
            </ReactPlaceholder>
          </Col>
        </Row>
        <Row>
          <Col className="control-label" sm={3}>
            Timezone:
          </Col>
          <Col sm={8}>
            <ReactPlaceholder rows={1} type="text" ready={account != null}>
              <select
                name="timezone"
                id="id_timezone"
                className="form-control"
                defaultValue={account?.timezone}
              >
                {timezoneOptions?.map((option) => (
                  <option value={option.value}>{option.name}</option>
                ))}
              </select>
              {errors.timezone && (
                <div style={{ color: "red", padding: 0 }}>
                  {errors.timezone}
                </div>
              )}
            </ReactPlaceholder>
          </Col>
        </Row>
        <Row>
          <Col sm={3}></Col>
          <Col sm={8}>
            Set your time zone to display critique and review deadlines in your
            local time.
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center">
              <button className="btn btn-primary" type="submit">
                Save
              </button>
              <button className="btn cancel" type="reset">
                Cancel
              </button>
            </div>
          </Col>
        </Row>
      </Table>
    </form>
  );
}
