/* Config file for the app */

const config = {
  BASE_API_URL: process.env.REACT_APP_BASE_API_URL,
  BASE_FE_URL: process.env.REACT_APP_BASE_FE_URL,
  WS_BASE_URL: process.env.REACT_APP_WS_BASE_URL,
  PUBLIC_POSTHOG_KEY: process.env.REACT_APP_PUBLIC_POSTHOG_KEY,
  PUBLIC_POSTHOG_HOST: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  GOOGLE_CAPTCHA_SECRET_KEY: process.env.REACT_APP_GOOGLE_CAPTCHA_SECRET_KEY,
  GOOGLE_CAPTCHA_PUBLIC_KEY: process.env.REACT_APP_GOOGLE_CAPTCHA_PUBLIC_KEY,
  STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
  STRIPE_SECRET_KEY: process.env.REACT_APP_STRIPE_SECRET_KEY,
  ANNUAL_PRICE_ID: process.env.REACT_APP_ANNUAL_PRICE_ID,
  QUARTERLY_PRICE_ID: process.env.REACT_APP_QUARTERLY_PRICE_ID,
};

export default {
  ...config,
};
