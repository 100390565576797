import DeleteCommentModal from "components/groups/details/groupDetailDeleteCommentModal";
import AuthenticationContext from "contexts/AuthenticationContext";
import { displayFormattedDate } from "pages/events/utils";
import { useContext, useState } from "react";
import { Card, Col, Row, Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import sendRequest from "services/dataService";
import { CurrentUserState, DiscussionComment } from "types";
import NewMessageForm from "../new-message-component/DiscussionNewCommentComponent";
import { useSelector } from "react-redux";
import EditDiscussionCommentModal from "components/groups/details/groupDetailEditDiscussionCommentModal";
import { Link } from "react-router-dom";
import config from "config";
import { AutoLink } from "utils";

import "./DiscussionCommentComponent.scss";

type DiscussionCommentComponentProps = {
  comment: DiscussionComment;
  parent?: DiscussionComment;
  isAdmin?: boolean;
  expandByDefault?: boolean;
  onPinUnpin: (pinned: boolean) => void;
  onDelete: () => void;
  onReply: () => void;
};

function DiscussionCommentComponent({
  comment,
  parent,
  isAdmin,
  expandByDefault = false,
  onPinUnpin,
  onDelete,
  onReply,
}: DiscussionCommentComponentProps) {
  const hasReplies = comment.replies?.length > 0;
  const [showReplies, setShowReplies] = useState(expandByDefault);
  const [showReplyForm, setShowReplyForm] = useState(false);
  const authenticationContext = useContext(AuthenticationContext);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const userState = useSelector(
    (state: { user: CurrentUserState }) => state.user
  );

  const handlePinUnpin = (comment: DiscussionComment) => {
    const token = authenticationContext.token || "";
    sendRequest({
      method: "PATCH",
      url: `api/v1.0/group/${comment.target_instance_id}/comments/${comment.id}/`,
      token,
      body: JSON.stringify({ pinned: !comment.pinned }),
    }).then((response) => {
      onPinUnpin(!comment.pinned);
      toast.success(
        comment.pinned
          ? "Comment unpinned successfully."
          : "Comment pinned successfully.",
        {
          position: "bottom-right",
        }
      );
    });
  };

  const onEdit = () => {
    onPinUnpin(false);
  };

  const handleDelete = async (comment: DiscussionComment) => {
    const token = authenticationContext.token || "";
    try {
      await sendRequest({
        method: "DELETE",
        url: `api/v1.0/group/${comment.target_instance_id}/comments/${comment.id}/`,
        token,
        body: null,
      });
      toast.success("Comment deleted successfully.", {
        position: "bottom-right",
      });
    } catch (error: any) {
      // toast.error(error.message, {
      //   position: "bottom-right",
      // });
    }
    onDelete();
  };
  const maxReplyLevel = 5;

  return (
    <>
      <Row
        className={"mb-2" + (parent ? " border-bottom" : "")}
        style={
          parent ? { paddingRight: 0, minWidth: "100%", marginRight: 0 } : {}
        }
      >
        <Col className="p-0">
          <Card className={parent ? "border-0" : ""}>
            <div className="p-3">
              {comment.pinned && !isAdmin && (
                <span
                  className="text-xs font-bold"
                  style={{
                    position: "absolute",
                    top: 1,
                    right: 8,
                    color: "#0a5c7d",
                  }}
                >
                  Pinned post
                </span>
              )}
              {comment.can_be_deleted && (
                <button
                  className="delete-button font-bold"
                  style={{
                    position: "absolute",
                    bottom: hasReplies ? 27 : 5,
                    right: 8,
                    background: "none",
                    border: "none",
                    padding: 0,
                    cursor: "pointer"
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setShowDeleteModal(true);
                  }}
                />
              )}
              <DeleteCommentModal
                comment={comment}
                onHide={(res) => {
                  if (res) {
                    handleDelete(comment);
                  }
                  setShowDeleteModal(false);
                }}
                show={showDeleteModal}
              />
              <EditDiscussionCommentModal
                comment={comment}
                show={showEditModal}
                onHide={(res) => {
                  if (res) {
                    onEdit();
                  }
                  setShowEditModal(false);
                }}
              />
              <Row key={comment.id} className="align-items-start">
                <Col xs="auto">
                  <img 
                    src={comment.user.avatar} 
                    alt={`${comment.user.pen_name}'s avatar`} 
                    style={{ 
                      width: 50,
                      height: 50,
                      borderRadius: "50%",
                      objectFit: "cover"
                    }} 
                  />
                </Col>
                <Col style={{ fontSize: "14px", lineHeight: "24px" }}>
                  <Row>
                    <Col>
                      <Link
                        to={`/profile/view/${comment.user.profile}`}
                        style={{ fontWeight: "bold" }}
                      >
                        {comment.user.pen_name}
                      </Link>
                      {" "}•{" "}
                      {comment.edited_at && (
                        <span style={{ fontStyle: "italic", marginRight: "4px" }}>
                          (edited)
                        </span>
                      )}
                      <span
                        style={{
                          fontSize: "14px",
                        }}
                      >
                        {displayFormattedDate(comment.date_created)}
                      </span>
                    </Col>
                    <Col xs="auto" className="d-flex">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="link"
                          id="comment-menu"
                          style={{
                            background: "none",
                            border: "none",
                            padding: 0,
                            color: "#666",
                            fontSize: "20px",
                            lineHeight: 1,
                            cursor: "pointer",
                            width: "24px",
                            height: "24px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            minWidth: "24px"
                          }}
                          className="dropdown-toggle-no-caret"
                        >
                          ...
                        </Dropdown.Toggle>

                        <Dropdown.Menu align="right">
                          {isAdmin && !parent && (
                            <Dropdown.Item eventKey="pin" onClick={() => handlePinUnpin(comment)}>
                              {comment.pinned ? "Unpin this post" : "Pin this post"}
                            </Dropdown.Item>
                          )}
                          {userState.data.id === comment.user.id && (
                            <Dropdown.Item eventKey="edit" onClick={() => setShowEditModal(true)}>
                              Edit
                            </Dropdown.Item>
                          )}
                          {comment.can_be_deleted && (
                            <Dropdown.Item eventKey="delete" onClick={() => setShowDeleteModal(true)}>
                              Delete
                            </Dropdown.Item>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  </Row>
                  {parent && (
                    <>
                      {" "}
                      <img
                        src={config.BASE_API_URL + "static/img/reply.png"}
                        alt="Reply to"
                      />{" "}
                      <Link
                        to={`/profile/view/${parent.user.id}`}
                        style={{ fontWeight: "bold", opacity: 0.6 }}
                      >
                        {parent.user.pen_name}
                      </Link>
                    </>
                  )}
                  {comment.text.split("\n").map((line, index) => (
                    <>
                      {index > 0 && <br />}
                      <span key={index}>{AutoLink(line)}</span>
                    </>
                  ))}{" "}
                  {comment.level < maxReplyLevel && (
                    <button
                      className="font-bold"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowReplyForm(!showReplyForm);
                      }}
                      style={{ fontSize: "15px", background: "none", border: "none", padding: 0, cursor: "pointer", color: "#0a5c7d" }}
                    >
                      Reply
                    </button>
                  )}
                </Col>
              </Row>
              {showReplyForm && (
                <>
                  <Row style={{ marginTop: 8 }}>
                    <NewMessageForm
                      groupId={comment.target_instance_id}
                      parentId={comment.id}
                      onPostMessage={() => {
                        setShowReplyForm(false);
                        setShowReplies(true);
                        onReply();
                      }}
                    />
                  </Row>
                </>
              )}
            </div>
            {hasReplies && !parent && (
              <div
                className="w-full"
                style={{
                  backgroundColor: "#f1f1f1",
                  textAlign: "right",
                  paddingRight: 8,
                  paddingLeft: 65,
                }}
              >
                <button
                  className="text-xs font-bold"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowReplies(!showReplies);
                  }}
                  style={{ background: "none", border: "none", padding: 0, cursor: "pointer" }}
                >
                  {showReplies
                    ? `Hide replies (${comment.replies_count})`
                    : `Show replies (${comment.replies_count})`}
                </button>
              </div>
            )}
          </Card>
        </Col>
      </Row>
      {hasReplies && (showReplies || parent) && (
        <>
          <div style={{ paddingLeft: "60px", paddingRight: 0, width: "100%" }}>
            {comment.replies.map((reply) => (
              <DiscussionCommentComponent
                key={reply.id}
                comment={reply}
                onPinUnpin={onPinUnpin}
                onDelete={onDelete}
                onReply={onReply}
                parent={comment}
                isAdmin={isAdmin}
                expandByDefault={expandByDefault}
              />
            ))}
          </div>
        </>
      )}
    </>
  );
}

export default DiscussionCommentComponent;