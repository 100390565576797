import AuthenticationContext from "contexts/AuthenticationContext";
import { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import sendRequest from "services/dataService";
import { CurrentUserState } from "types";

export default function ChangePlanModal({
  show,
  plan,
  onHide,
}: {
  show: Boolean;
  plan: string;
  onHide: (deleted: Boolean) => void;
}) {
  const authenticationContext = useContext(AuthenticationContext);
  const userState = useSelector(
    (state: { user: CurrentUserState }) => state.user
  );

  const onSubmit = async (e: any) => {
    e.preventDefault();
    const url = `api/v1.0/user/${userState.data.id}/subscription/`;
    sendRequest({
      url,
      method: "PATCH",
      token: authenticationContext.token || "",
      body: JSON.stringify({
        plan: plan,
      }),
    })
      .then((response) => {
        onHide(true);
        toast.success("Subscription updated succesfully.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      })
      .catch((error: any) => {
        toast.error(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  return (
    <Modal show={show} centered onHide={() => onHide(false)}>
      <Modal.Header>
        <Modal.Title>Confirm Plan Change</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to change to {plan} plan?</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="primary purple" onClick={onSubmit}>
          Ok
        </Button>
        <Button
          variant="secondary"
          className="secondary grey"
          onClick={() => onHide(false)}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
