import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-placeholder/lib/reactPlaceholder.css";
import { useSelector } from "react-redux";
import { CurrentUserState } from "types";
import { usePostHog } from "posthog-js/react";

import Footer from "components/footer/Footer";
import AppRoutes from 'routes/AppRoutes';

const App = () => {
  const posthog = usePostHog();
  const userState = useSelector(
    (state: { user: CurrentUserState }) => state.user
  );

  useEffect(() => {
    if (userState.data.id) {
      posthog?.identify(`user-${userState.data.id}`, {
        name: userState.data.pen_name,
      });
    }
  }, [posthog, userState.data]);

  return (
    <Router>
      <div className="container-fluid">
        <div className="row ink-app">
          <AppRoutes />
          <Footer />
          <ToastContainer />
        </div>
      </div>
    </Router>
  );
};

export default App;
