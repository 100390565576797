import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ProtectedRoute from "protected-route/ProtectedRoute";
import Header from "components/header/Header";
import Footer from "components/footer/Footer";
import Home from "pages/home/Home";
// import Forum from "pages/forum/Forum";
import Login from "pages/login/Login";
import Events from "pages/events/Events";
// import ForumItem from "pages/forum-item/ForumItem";
import ProCritiques from "pages/workshops/ProCritiques";
import CritiqueGuidelines from "pages/resources/CritiqueGuidelines";
import PictureBooksCritiqueGuide from "pages/resources/PictureBooksCritiqueGuide";
import CritiqueGuidelinesFiction from "pages/resources/CritiqueGuidelinesFiction";
import KidlitLiteraryAgentsList from "pages/resources/KidlitLiteraryAgentsList";
import OnePageSynopsis from "pages/resources/OnePageSynopsis";
import HowTos from "pages/resources/HowTo";
import Logout from "pages/logout/Logout";
import EditProfile from "pages/user-profile/EditProfile/EditProfile";
import Profile from "pages/user-profile/Profile/Profile";
import Team from "pages/team/Team";
import EventDetail from "pages/events/eventDetail/EventDetail";
import Archive from "pages/archive/Archive";
import ArchivedEventDetail from "pages/archive/archivedEventDetail/ArchivedEventDetail";
import Dashboard from "pages/dashboard/Dashboard";
// import WorkWithUs from "pages/work-with-us/WorkWithUs";
import TermsOfService from "pages/terms-of-service/TermsOfService";
import Faq from "pages/faq/Faq";
import CodeOfConduct from "pages/code-of-conduct/CodeOfConduct";
import EducatorsAndWritingPrograms from "pages/educators-and-writing-programs/EducatorsAndWritingPrograms";
import Testimonials from "pages/testimonials/Testimonials";
// import Press from "pages/press/Press";
import WhoCritiqued from "pages/groups/who-critiqued/WhoCritiqued";
import Inbox from "pages/messages/inbox/Inbox";
import Sent from "pages/messages/sent/Sent";
import Trash from "pages/messages/trash/Trash";
import Compose from "pages/messages/compose/Compose";
import View from "pages/messages/view/View";
import EditCritique from "pages/critique/edit/EditCritique";
import GroupNotificationSettings from "pages/dashboard/groups/GroupNotificationSettings";
import CommunityLedInitiatives from "pages/community-led-initiatives/CommunityLedInitiatives";
import WhatKindsOfGroupsAreHostedOnInkedVoices from "pages/what-kinds-of-groups-are-hosted-on-inked-voices/WhatKindsOfGroupsAreHostedOnInkedVoices";
import AllCritiques from "pages/critique/all/AllCritiques";
import Pricing from "pages/pricing/Pricing";
import GroupDetailPage from "pages/groups/GroupDetail";
import GroupAdmin from "pages/groups/group-admin/GroupAdmin";
import GroupEditProfile from "pages/groups/edit-profile/GroupEditProfile";
import NewGroup from "pages/groups/new-group/NewGroup";
import SearchPage from "pages/search/Search";
import AccountPage from "pages/account/Account";
import GroupPublicProfile from "pages/groups/public-profile/GroupPublicProfile";
// import AccountPage from "pages/account/Account";

import {
  homeRoute,
  loginRoute,
  logoutRoute,
  teamRoute,
  dashboardRoute,
  newGroupRoute,
  groupAdminRoute,
  whoCritiquedRoute,
  groupNotificationsRoute,
  groupEditRoute,
  groupDetailRoute,
  groupSearchRoute,
  groupPublicProfileRoute,
  eventsRoute,
  archiveRoute,
  eventDetailRoute,
  archivedEventDetailRoute,
  proCritiquesRoute,
  faqRoute,
  pricingRoute,
  testimonialsRoute,
  codeOfConductRoute,
  termsOfServiceRoute,
  educatorsAndWritingProgramsRoute,
} from "./endpoints";

const Routes = () => {
  return (
    <BrowserRouter>
      <Header />
      <Route path="/account/edit/" component={AccountPage} />
      <Route path={homeRoute} exact component={Home} />
      <Route path={teamRoute} component={Team} />
      <Route path={dashboardRoute} component={Dashboard} />
      <Route path={loginRoute} component={Login} />
      <Route path={logoutRoute} component={Logout} />

      {/* Event routes */}
      <Route path={eventDetailRoute(":event_type_friendly_name", ":id")} component={EventDetail} />
      <Route path={eventsRoute} component={Events} />
      <Route path={archivedEventDetailRoute(":id")} component={ArchivedEventDetail} />
      <Route path={archiveRoute} component={Archive} />
      <Route path={proCritiquesRoute} component={ProCritiques} />

      {/* Forum routes */}
      {/* <ProtectedRoute exact path="/forum/:id" component={ForumItem} />
      <ProtectedRoute exact path="/forum" component={Forum} /> */}

      {/* Profile routes */}
      <ProtectedRoute exact path="/profile/view/:id" component={Profile} />
      <ProtectedRoute exact path="/profile/edit" component={EditProfile} />

      {/* Group routes */}
      <Switch>
        <ProtectedRoute exact path={newGroupRoute} component={NewGroup} />
        <ProtectedRoute exact path={groupAdminRoute(":groupId")} component={GroupAdmin} />
        <ProtectedRoute exact path={whoCritiquedRoute(":id")} component={WhoCritiqued} />
        <ProtectedRoute exact path={groupNotificationsRoute(":groupId")} component={GroupNotificationSettings} />
        <ProtectedRoute exact path={groupEditRoute(":groupId")} component={GroupEditProfile} />
        <ProtectedRoute exact path={groupDetailRoute(":id", ":slug")} component={GroupDetailPage} />
        <ProtectedRoute exact path={groupSearchRoute} component={SearchPage} />
        <Route exact path={groupPublicProfileRoute(":id", ":name")} component={GroupPublicProfile} />
      </Switch>

      {/* Resource routes */}
      <ProtectedRoute exact path="/resources/critique-guidelines/" component={CritiqueGuidelines} />
      <ProtectedRoute exact path="/resources/picture-books-critique-guide/" component={PictureBooksCritiqueGuide} />
      <ProtectedRoute exact path="/resources/critique-guidelines-fiction/" component={CritiqueGuidelinesFiction} />
      <ProtectedRoute exact path="/resources/kidlit-literary-agents-list/" component={KidlitLiteraryAgentsList} />
      <ProtectedRoute exact path="/resources/one-page-synopsis/" component={OnePageSynopsis} />
      <ProtectedRoute exact path="/resources/how-to-s/" component={HowTos} />
      <Route exact path={faqRoute} component={Faq} />
      <Route exact path={testimonialsRoute} component={Testimonials} />
      <Route exact path={termsOfServiceRoute} component={TermsOfService} />
      <Route exact path={codeOfConductRoute} component={CodeOfConduct} />
      <Route exact path={pricingRoute} component={Pricing} />
      <Route exact path={educatorsAndWritingProgramsRoute} component={EducatorsAndWritingPrograms} />
      {/* <Route exact path="/work-with-us/" component={WorkWithUs} /> */}
      {/* <Route exact path="/about/press/" component={Press} /> */}

      {/* Messages routes */}
      <ProtectedRoute exact path={["/messages/compose", "/messages/compose/:contactIds", "/messages/compose/:contactIds/:subjectParam" ]} component={Compose} />
      <ProtectedRoute exact path={["/messages/reply/", "/messages/reply/:messageId", "/messages/reply_all/:rec/:messageId",]} component={Compose} />
      <ProtectedRoute exact path="/messages/inbox" component={Inbox} />
      <ProtectedRoute exact path="/messages/sent" component={Sent} />
      <ProtectedRoute exact path="/messages/trash" component={Trash} />
      <ProtectedRoute exact path="/messages/view/:messageId" component={View} />

      {/* Critique routes */}
      <ProtectedRoute exact path="/critique/edit/:id" component={EditCritique} />
      <ProtectedRoute exact path="/critique/all_for/:id/:submissionClassName" component={AllCritiques} />

      {/* Other routes */}
      <Route exact path="/community-led-initiatives/" component={CommunityLedInitiatives} />
      <Route exact path="/what-kinds-of-groups-are-hosted-on-inked-voices/" component={WhatKindsOfGroupsAreHostedOnInkedVoices} />
    </BrowserRouter>
  );
};

export default Routes;
