// Static routes
export const homeRoute = '/';
export const teamRoute = '/team';
export const dashboardRoute = '/dashboard';

// Resource routes
export const faqRoute = '/faq';
export const pricingRoute = '/pricing';
export const testimonialsRoute = '/testimonials';
export const codeOfConductRoute = '/code-of-conduct';
export const termsOfServiceRoute = '/terms-of-service';
export const educatorsAndWritingProgramsRoute = '/educators-and-writing-programs/';

// Auth routes
export const loginRoute = '/login';
export const logoutRoute = '/logout';
export const signupRoute = '/signup';
export const signupSelectGenresRoute = (userId) => `/signup/select-genres/${userId}`;
export const forgotPasswordRoute = '/forgot-password';
export const resetPasswordRoute = '/reset-password';
export const changePasswordRoute = '/change-password';

// Subscription routes
export const subscriptionRoute = '/subscription';
export const subscriptionCancelRoute = '/subscription/cancel';
export const subscriptionChangeRoute = '/subscription/change';
export const subscriptionUpdateRoute = '/subscription/update';
export const subscriptionUserRoute = (userId) => `/subscription/${userId}`;

// Group routes
export const newGroupRoute = '/group/new/';
export const groupAdminRoute = (groupId) => `/group/admin/${groupId}/`;
export const groupDetailRoute = (id, slug) => `/group/${id}/${slug}/`;
export const groupEditRoute = (groupId) => `/group/edit/${groupId}/`;
export const groupNotificationsRoute = (groupId) => `/group/notifications/${groupId}/`;
export const groupPublicProfileRoute = (id, name) => `/group/public/${id}/${name}/`;
export const groupSearchRoute = '/group/search/';
export const whoCritiquedRoute = (id) => `/group/${id}/who-critiqued/`;

// Event routes
export const eventsRoute = '/events';
export const archiveRoute = '/archive';
export const eventDetailRoute = (event_type_friendly_name, id) => `/event/${event_type_friendly_name}/${id}/`;
export const archivedEventDetailRoute = (id) => `/archived/${id}/`;
export const proCritiquesRoute = '/pro-critiques/';
