import React, { useState, useEffect } from "react";
import { Container, Col, Form, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import { useHistory, useLocation } from "react-router-dom";
import sendRequest from "services/dataService";

import config from "config";
import { signupSelectGenresRoute } from "routes/endpoints";
import "./SignUp.scss";

interface SignUpFormData {
  email: string;
  captcha: string;
  pen_name: string;
  password1: string;
  password2: string;
  referral_user?: string;
}

const SignUp = () => {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<SignUpFormData>();

  const history = useHistory();
  const location = useLocation();
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);

  useEffect(() => {
    // Extract referral parameter from URL
    const searchParams = new URLSearchParams(location.search);
    const referralCode = searchParams.get('referral');
    
    if (referralCode) {
      setValue('referral_user', referralCode);
    }
  }, [location, setValue]);

  const onSubmit = async (data: SignUpFormData) => {
    if (!captchaToken) {
      toast.error("Please complete the CAPTCHA.");
      return;
    }

    if (data.password1 !== data.password2) {
      setError("password2", {
        type: "manual",
        message: "Passwords do not match.",
      });
      return;
    }

    try {
      const response = await sendRequest({
        url: `${config.BASE_API_URL}api/v1.0/account/signup/`,
        method: "POST",
        token: "",
        body: JSON.stringify({ ...data, captcha: captchaToken }),
      });

      toast.success("Account created successfully! Please check your email.");
      history.push(signupSelectGenresRoute(response.user_id));
    } catch (error) {
      toast.error("Registration failed. Please try again.");
    }
  };

  return (
    <Container className="signup-container">
      <Col md={{ span: 8, offset: 2 }} className="sign-up">
        <h1>Sign Up</h1>
        <h4>Create your account:</h4>

        <Form onSubmit={handleSubmit(onSubmit)}>
          {/* Name with Tooltip */}
          <Form.Group className="form-group">
            <Form.Label style={{ fontWeight: "bold", marginTop: 10 }}>
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="name-tooltip">
                    Most people use their first and last name or their first, plus a made-up last.
                    If you choose not to use your actual name, the name must be plausible.
                  </Tooltip>
                }
              >
                <span className="ico-help" style={{ position: "inherit" }}></span>
              </OverlayTrigger>
              Name{" "}
            </Form.Label>
            <Form.Control
              type="text"
              {...register("pen_name", { required: "Name is required." })}
              className="col-md-6"
            />
            {errors.pen_name && (
              <small className="text-danger">{errors.pen_name.message}</small>
            )}
          </Form.Group>

          {/* Email */}
          <Form.Group className="form-group">
            <Form.Label style={{ fontWeight: "bold", marginTop: 10 }}>Email Account</Form.Label>
            <Form.Control
              type="email"
              {...register("email", {
                required: "Email is required.",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Invalid email format.",
                },
              })}
              className="col-md-6"
            />
            {errors.email && (
              <small className="text-danger">{errors.email.message}</small>
            )}
          </Form.Group>

          {/* Password */}
          <Form.Group className="form-group">
            <Form.Label style={{ fontWeight: "bold", marginTop: 10 }}>Choose a Password</Form.Label>
            <Form.Control
              type="password"
              {...register("password1", {
                required: "Password is required.",
                minLength: {
                  value: 6,
                  message: "Password must be at least 6 characters.",
                },
              })}
              className="col-md-6"
            />
            {errors.password1 && (
              <small className="text-danger">{errors.password1.message}</small>
            )}
          </Form.Group>

          {/* Confirm Password */}
          <Form.Group className="form-group">
            <Form.Label style={{ fontWeight: "bold", marginTop: 10 }}>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              {...register("password2", { required: "Please confirm password." })}
              className="col-md-6"
            />
            {errors.password2 && (
              <small className="text-danger">{errors.password2.message}</small>
            )}
          </Form.Group>

          {/* Referral User (Hidden) */}
          <Form.Group className="form-group" style={{ display: 'none' }}>
            <Form.Control
              type="text"
              {...register("referral_user")}
              className="col-md-6"
            />
          </Form.Group>

          {/* CAPTCHA using react-google-recaptcha */}
          <Form.Group className="form-group" style={{ marginTop: "20px", marginBottom: "20px" }}>
            <ReCAPTCHA
              sitekey={config.GOOGLE_CAPTCHA_PUBLIC_KEY || ""}
              onChange={(token: any) => setCaptchaToken(token)}
            />
            {errors.captcha && (
              <small className="text-danger">{errors.captcha.message}</small>
            )}
          </Form.Group>

          <Button type="submit" className="btn btn-large purple" disabled={isSubmitting}>
            {isSubmitting ? "Registering..." : "Register User"}
          </Button>
        </Form>
      </Col>
    </Container>
  );
};

export default SignUp;
