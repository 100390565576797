import AuthenticationContext from "contexts/AuthenticationContext";
import { useContext, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import sendRequest from "services/dataService";
import { CurrentUserState } from "types";

export default function CancelSubscriptionModal({
  show,
  onHide,
}: {
  show: Boolean;
  onHide: (deleted: Boolean) => void;
}) {
  const authenticationContext = useContext(AuthenticationContext);
  const userState = useSelector(
    (state: { user: CurrentUserState }) => state.user
  );
  const [disabled, setDisabled] = useState(false);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setDisabled(true);
    const url = `api/v1.0/user/${userState.data.id}/cancel-subscription/`;
    sendRequest({
      url,
      method: "PATCH",
      token: authenticationContext.token || "",
      body: null,
    })
      .then((response) => {
        onHide(true);
        toast.success("Subscription cancelled succesfully.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      })
      .catch((error: any) => {
        toast.error(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      })
      .finally(() => {
        setDisabled(false);
      });

  };

  return (
    <Modal show={show} centered onHide={() => {setDisabled(false); onHide(false);}}>
      <Modal.Header>
        <Modal.Title>Confirm Cancellation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to cancel your subscription?
      </Modal.Body>
      <Modal.Footer>
        <Button 
          variant="primary" 
          className="primary purple" 
          onClick={onSubmit}
          disabled={disabled}
          >
          Ok
        </Button>
        <Button
          variant="secondary"
          className="secondary grey"
          onClick={() => {
            setDisabled(false);
            onHide(false)
          }}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
