import { Container } from "react-bootstrap";
import "./Account.scss";
import AccountForm from "components/account/AccountForm";
import PasswordForm from "components/account/PasswordResetForm";
import SubscriptionForm from "components/account/SubscriptionForm";

export default function AccountPage() {
  return (
    <Container className="profile-edit">
      <h1>Edit User Settings</h1>
      <AccountForm />
      <PasswordForm />
      <h2 style={{ paddingLeft: "15px" }}>Subscription</h2>
      <SubscriptionForm />
    </Container>
  );
}
