import { useContext, useEffect, useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Select from 'react-select';
import { toast } from "react-toastify";

import useFetch from "custom-hooks/useFetch";
import sendRequest from "services/dataService";
import AuthenticationContext from "contexts/AuthenticationContext";
import MessagesLayout from "../MessagesLayout";
import { InkedMessage, isReplyAll } from "../types/Message";
import {
  BaseMessage,
  BasePaginatedResponse,
  BaseUser,
  CurrentUserState,
} from "types";
import "./Compose.scss";

export default function Compose() {
  const history = useHistory();
  const location = useLocation<{ message: InkedMessage }>();
  const { messageId } = useParams<{ messageId: string }>();
  const { contactIds } = useParams<{ contactIds: string }>();
  const { subjectParam } = useParams<{ subjectParam: string }>();
  const [replyingMessage, setReplyingMessage] = useState<InkedMessage | null>(null);
  const [message, setMessage] = useState('');
  const [subject, setSubject] = useState('');
  const [state, fetchData] = useFetch();
  const [users, setUsers] = useState<BaseUser[]>([]);
  const [usersLoaded, setUsersLoaded] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<{ value: string; label: string }[]>([]);
  const authenticationContext = useContext(AuthenticationContext);
  const DropdownIndicator = () => null;
  const [inputValue, setInputValue] = useState('');
  
  const userOptions = users.map((user: any) => ({
    value: user.id.toString(),
    label: user.pen_name,
  }));
  
  const userState = useSelector(
    (state: { user: CurrentUserState }) => state.user
  );

  async function fetchMessage() {
    const inkedMessage = location.state && location.state.message
      ? location.state.message
      : await getMessageFromApi(parseInt(messageId));

    if (inkedMessage) {
      setReplyingMessage(inkedMessage);
      setSubject(`Re: ${inkedMessage?.subject}`);
      setMessage(formatReplyMessage(inkedMessage));
    }
    setUsersLoaded(true);
  }

  useEffect(() => {
    const to: { value: string; label: string }[] = getCorrespondents();
    setSelectedUsers(to);
    if (subjectParam) {
      setSubject(subjectParam);
    }
  }, [usersLoaded]);

  useEffect(() => {
    fetchData({
      url: `api/v1.0/user/active/`,
      method: "GET",
      token: authenticationContext.token,
    })
      .then((res) => {
        setUsers(res);
        fetchMessage();
      })
      .catch((error) => {
        console.error("Error fetching users data:", error);
        setUsersLoaded(true);
      });
  }, [authenticationContext.token]);

  const onInputChange = (value: string) => {
    setInputValue(value);
    return value;
  };

  const formatReplyMessage = (message: InkedMessage) => {
    const quotedBody = message.body
      .split('\n')
      .map(line => `> ${line}`)
      .join('\n');
    return `${message.sender.pen_name} wrote:\n${quotedBody}`;
  };

  const getCorrespondents = () => {
    const to: { value: string; label: string; }[] = [];
    if (
      replyingMessage?.sender &&
      // Exclude sender only when sender IS the current user and current user is not in the recipients list.
      !(replyingMessage.sender.id === userState.data.id && !replyingMessage.recipients?.some(recipient => recipient.id === userState.data.id) && replyingMessage.recipients && replyingMessage.recipients.length > 1)
    ) {
      const sender = { value: replyingMessage?.sender.id.toString(), label: replyingMessage?.sender.pen_name };
      to.push(sender);
    }
    if (replyingMessage && isReplyAll(replyingMessage)) {
      replyingMessage.recipients?.forEach((recipient) => {
        if (recipient.id !== replyingMessage.sender.id
            && !(recipient.id === userState.data.id 
               && replyingMessage.sender.id !== userState.data.id)
          ) 
        {
          to.push({ value: recipient.id.toString(), label: recipient.pen_name });
        }
      });
    }
    if (contactIds) {
      const contactIdsArray = contactIds.split('+');
      contactIdsArray.forEach((id: string) => {
        const contactOption = userOptions.find(option => option.value === id);
        if (contactOption) {
          to.push(contactOption);
        }
      });
    }
    return to;
  }

  const getMessageFromApi = async (messageId: number): Promise<InkedMessage | null> => {
    try {
      const data = await fetchData({
        url: `api/v1.0/messages/${messageId}`,
        method: "GET",
        token: authenticationContext.token,
      });

      const isReplyAllUrl = location.pathname.includes("reply_all");

      const inkedMessage: InkedMessage = {
        sender: { pen_name: data.sender.pen_name, id: data.sender.id, profile_id: data.sender.profile_id },
        subject: data.subject,
        body: data.body,
        sent_at: data.sent_at,
        recipients: isReplyAllUrl ? (data.recipients || null) : null,
      };
      return inkedMessage;
    } catch (error) {
      console.error("Error fetching message data:", error);
      return null;
    }
  }

  const handleSelectChange = (selectedOptions: any) => {
    setSelectedUsers(selectedOptions);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const data = {
      recipient: selectedUsers.map((option: any) => option.value),
      subject: subject,
      body: message,
    };

    sendRequest({
      url: `api/v1.0/messages/compose/`,
      method: "POST",
      token: authenticationContext.token || "",
      body: JSON.stringify(data),
    })
      .then((response: any) => {
        // Reset form or show success message
        toast.success("Message successfully sent.");
        setSelectedUsers([]);
        history.push("/messages/inbox");
      })
      .catch((error: any) => {
        const errorData = typeof error.message === 'string' ? JSON.parse(error.message) : error.response.data;
        toast.error("Failed to send message.");
      });
  };

  const rightCol = (
    <Col md={9}>
      <h1>Compose Message</h1>
      <Form onSubmit={handleSubmit} method="post" className="message-compose">
        <Form.Control
          type="hidden"
          name="csrfmiddlewaretoken"
          defaultValue="DAw9lY4UPP6kPRI74421R9AyclD4ZMrHYj3RxCh0pUzYf9RS4bmR75x8nUA3k8Li"
        />
        <Form.Group as={Row} controlId="id_recipient">
          <Form.Label column sm={1}>
            Recipient
          </Form.Label>
          <Col sm={9}>
            <Select
              isMulti
              value={selectedUsers}
              onChange={handleSelectChange}
              options={userOptions}
              className="recipient-multi-select"
              classNamePrefix="recipient-select"
              placeholder="[Type the user name]"
              components={{ DropdownIndicator }}
              onInputChange={onInputChange}
              menuIsOpen={inputValue.length >= 2}
              filterOption={(option, rawInput) =>
                rawInput.length >= 2 &&
                option.label.toLowerCase().includes(rawInput.toLowerCase())
              }
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="id_subject">
          <Form.Label column sm={1}>
            Subject
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              type="text"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              name="subject"
              maxLength={120}
              required />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="id_body">
          <Form.Label column sm={1}>
            Message
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              as="textarea"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              name="body"
              className="message-body"
              required />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col sm={{ span: 9, offset: 1 }} className="text-end">
            <Button type="submit" className="btn">
              Send
            </Button>
          </Col>
        </Form.Group>
      </Form>
    </Col>
  );
  return (
    <Container className="inbox">
      <MessagesLayout
        unreadCount={userState.data.unread_messages || 0}
        rightCol={rightCol}
      />
    </Container>
  );
}
